import to from 'await-to-js';
import { Component, OnInit } from "@angular/core";
import { DataRepoFactoringPartner } from "@app/data-repo/factoring-partner";
import { UIHelper } from '@services/UIHelper';
import { environment } from '@env/environment';
import { Utils } from '@services/utils';
import { DateUtil } from '@services/date-utils';
import { ResponseUrlAccessTokenListItemUI } from './interface';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { NzModalService } from 'ng-zorro-antd/modal';
dayjs.extend(relativeTime);

@Component({
  selector: '[factoring-partner-url]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],
})
export class FactoringPartnerUrl implements OnInit {
  listData: ResponseUrlAccessTokenListItemUI[] = [];
  isLoading = false;
  isCreating = false;
  get isNoData() { return this.listData.length == 0 }
  constructor(private dataRepo: DataRepoFactoringPartner, private modal: NzModalService) {}

  ngOnInit(): void {
    this.fetchData();
  }

  private async fetchData() {
    this.isLoading = true;
    const [err, data] = await to(this.dataRepo.getActiveTokens());
    if (err) {
      UIHelper.showErr(err);
    } else {
      this.listData = data?.list_data ?? [];
    }
    this.isLoading = false;
  }

  getUrl(item: ResponseUrlAccessTokenListItemUI) {
    return `${environment.factoringPartnerWebUrl}/${item.token}/rate-confirm`;
  }

  isExpired(item: ResponseUrlAccessTokenListItemUI): boolean {
    return new Date(item.expireWhen).getTime() < Date.now();
  }

  showExpires(item: ResponseUrlAccessTokenListItemUI) {
    const text = dayjs(item.expireWhen).fromNow();
    if (this.isExpired(item)) {
      return `Expired ${text}`;
    } else {
      return `Expires ${text}`;
    }
  }

  showExpireWhen(item: ResponseUrlAccessTokenListItemUI) {
    return DateUtil.format(item.expireWhen, 'MMM D, YYYY h:mm:ss a');
  }

  async onBtnCreate() {
    if (this.isCreating) {
      return;
    }
    this.isCreating = true;
    const [err] = await to(this.dataRepo.genToken());
    if (err) {
      UIHelper.showErr(err);
    } else {
      UIHelper.showInfo(`New access URL has been generated`);
      this.fetchData();
    }
    this.isCreating = false;
  }

  onBtnRefresh() {
    this.fetchData();
  }

  private async revoke(item: ResponseUrlAccessTokenListItemUI) {
    item.isLoading = true;
    const [err] = await to(this.dataRepo.revokeToken(item.token));
    if (err) {
      UIHelper.showErr(err);
    } else {
      UIHelper.showInfo(`URL has been revoked`);
      item.isRevoked = true;
    }
    item.isLoading = false;
  }

  onBtnRevoke(item: ResponseUrlAccessTokenListItemUI) {
    this.modal.create({
      nzContent: `Are you sure to revoke this URL?`,
      nzOkText: 'Revoke',
      nzOkDanger: true,
      nzOnOk: () => this.revoke(item),
    })
  }

  onBtnCopy(item: ResponseUrlAccessTokenListItemUI) {
    const url = this.getUrl(item);
    Utils.copyTextToClipboard(url, err => {
      if (err) UIHelper.showErr(err); else UIHelper.showInfo('URL has been copied');
    })
  }

  canRevoke(item: ResponseUrlAccessTokenListItemUI): boolean {
    return !item.isLoading && !item.isRevoked && !this.isExpired(item);
  }
}