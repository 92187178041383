<div class="section flex">
  <button nz-button (click)="onBtnCreate()" [disabled]="isCreating">
    <i *ngIf="!isCreating" nz-icon nzType="plus" nzTheme="outline"></i>
    <i *ngIf="isCreating" nz-icon nzType="loading" nzTheme="outline"></i>
    Generate new URL
  </button>
  <button nz-button (click)="onBtnRefresh()" [disabled]="isLoading" class="left10">Refresh</button>
</div>
<div class="section">
  <nz-table nzBordered="true"
    [nzData]="listData" nzSize="small"
    [nzLoading]="isLoading" [nzShowPagination]="false"
    [nzLoadingIndicator]="tplLoading"
    [nzNoResult]="tplNoData">
    <ng-template #tplNoData>
      <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th>URL</th>
        <th colspan="3">Created</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of listData; let i = index" [ngClass]="{'deleted': (item.isRevoked || isExpired(item))}">
        <td>
          <code>{{getUrl(item)}}</code>
          <i nz-icon nzType="copy" class="left10 clickable" nzTheme="outline" (click)="onBtnCopy(item)"></i>
        </td>
        <td class="border-right-none">
          <when-by-for-list [data]="item.insert"></when-by-for-list>
        </td>
        <td class="border-right-none">
          <div nz-tooltip [nzTooltipTitle]="showExpireWhen(item)">{{showExpires(item)}}</div>
        </td>
        <td style="width: 100px;">
          <button *ngIf="canRevoke(item)" nz-button nzDanger nzType="link" (click)="onBtnRevoke(item)">Revoke</button>
          <i *ngIf="item.isLoading" nz-icon nzType="loading" nzTheme="outline"></i>
        </td>
      </tr>
    </tbody>

  </nz-table>
</div>